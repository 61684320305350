import React, { useState } from 'react';
import {
  Card,
  Form,
  Row,
  Col,
  Button,
  Table
} from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SubtleBadge from 'components/common/SubtleBadge';

// Sample data - in a real app, this would come from an API
const initialData = [
  {
    id: 1,
    type: 'Day off',
    employee: 'John Smith',
    date: 'January 5th, 2024',
    submissionDate: '2023-12-20',
    status: 'pending'
  },
  {
    id: 2,
    type: 'Evening Shift',
    employee: 'Jane Doe',
    date: 'Every Thursday',
    submissionDate: '2023-12-19',
    status: 'approved'
  },
  {
    id: 3,
    type: 'Night Shift',
    employee: 'Mike Johnson',
    date: 'January 10th, 2024',
    submissionDate: '2023-12-18',
    status: 'rejected'
  }
];

const ReviewRequests = () => {
  const [statusFilter, setStatusFilter] = useState('all');
  const [requests, setRequests] = useState(initialData);
  const [searchTerm, setSearchTerm] = useState('');

  const handleStatusChange = (id, newStatus) => {
    setRequests(prevRequests => 
      prevRequests.map(request => 
        request.id === id ? { ...request, status: newStatus } : request
      )
    );
  };

  const filteredRequests = requests
    .filter(request => 
      (statusFilter === 'all' || request.status === statusFilter) &&
      (searchTerm === '' || 
        request.type.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.employee.toLowerCase().includes(searchTerm.toLowerCase()) ||
        request.date.toLowerCase().includes(searchTerm.toLowerCase())
      )
    )
    .sort((a, b) => {
      if (a.status === 'pending' && b.status !== 'pending') return -1;
      if (a.status !== 'pending' && b.status === 'pending') return 1;
      return new Date(b.submissionDate) - new Date(a.submissionDate);
    });

  return (
    <Card>
      <Card.Header>
        <Row className="flex-between-center">
          <Col xs="auto">
            <h6 className="mb-0">Review Requests</h6>
          </Col>
          <Col xs={12} md="auto" className="mt-3 mt-md-0">
            <Row className="g-3 align-items-center">
              <Col xs="auto">
                <Form.Select 
                  size="sm" 
                  value={statusFilter} 
                  onChange={(e) => setStatusFilter(e.target.value)}
                  style={{ width: '150px' }}
                >
                  <option value="all">All Status</option>
                  <option value="pending">Pending</option>
                  <option value="approved">Approved</option>
                  <option value="rejected">Rejected</option>
                </Form.Select>
              </Col>
              <Col xs="auto">
                <Form.Control
                  size="sm"
                  type="search"
                  placeholder="Search requests..."
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                  style={{ width: '200px' }}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className="p-0">
        <Table responsive className="fs--1 mb-0">
          <thead>
            <tr className="bg-200 text-900 text-nowrap">
              <th>Request Type</th>
              <th>Employee</th>
              <th>Submitted On</th>
              <th>Status</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {filteredRequests.map(request => (
              <tr key={request.id} className="align-middle">
                <td>
                  <div className="fw-semi-bold">{request.type}</div>
                  <div className="text-600">on {request.date}</div>
                </td>
                <td>
                  <div className="fw-semi-bold">{request.employee}</div>
                </td>
                <td>
                  <div className="text-600">{request.submissionDate}</div>
                </td>
                <td>
                  <SubtleBadge bg={
                    request.status === 'approved' 
                      ? 'success' 
                      : request.status === 'rejected'
                      ? 'danger'
                      : 'warning'
                  }>
                    {request.status.charAt(0).toUpperCase() + request.status.slice(1)}
                  </SubtleBadge>
                </td>
                <td>
                  <div>
                    <Button 
                      variant={request.status === 'approved' ? 'falcon-success' : 'outline-success'}
                      size="sm" 
                      className="me-2"
                      onClick={() => handleStatusChange(request.id, 'approved')}
                      disabled={request.status === 'approved'}
                    >
                      <FontAwesomeIcon icon="check" className="me-1" />
                      Approve
                    </Button>
                    <Button 
                      variant={request.status === 'rejected' ? 'falcon-danger' : 'outline-danger'}
                      size="sm"
                      onClick={() => handleStatusChange(request.id, 'rejected')}
                      disabled={request.status === 'rejected'}
                    >
                      <FontAwesomeIcon icon="times" className="me-1" />
                      Reject
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
            {filteredRequests.length === 0 && (
              <tr>
                <td colSpan="5" className="text-center py-3">
                  No requests found
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

export default ReviewRequests; 