export const navItems = [
  { label: 'Collective Agreement', active: true },
  { label: 'Format' },
  { label: 'Shifts' },
  { label: 'Staff' },
  { label: 'Holidays' },
  { label: 'Requests' },
  { label: 'Coverage' },
  { label: 'Review' }
];

export const unionOptions = [
  { label: 'BCNU', value: 'bcnu' },
  { label: 'HLU', value: 'hlu' },
  { label: 'BC EHS', value: 'bc_ehs' },
  { label: 'Custom', value: 'custom' }
];

export const rotationOptions = [
  { label: 'Line Rotation', value: 'line' },
  { label: 'Block Rotation', value: 'block' },
  { label: 'Custom', value: 'custom' }
];

export const weekDays = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday'
];

export const defaultShifts = [
  {
    id: 'day',
    name: 'Day Shift',
    label: 'D',
    start: '07:00',
    end: '15:00',
    enabled: true,
    minConsecutive: 2,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'evening',
    name: 'Evening Shift',
    label: 'E',
    start: '15:00',
    end: '23:00',
    enabled: true,
    minConsecutive: 2,
    maxConsecutive: 5,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'night',
    name: 'Night Shift',
    label: 'N',
    start: '23:00',
    end: '07:00',
    enabled: true,
    minConsecutive: 2,
    maxConsecutive: 4,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  },
  {
    id: 'off',
    name: 'Off Day',
    label: 'O',
    start: '',
    end: '',
    enabled: true,
    minConsecutive: 1,
    maxConsecutive: 3,
    forbiddenSuccessors: [],
    penalizedSuccessors: [],
    penalty: 100
  }
];

export const defaultRules = [
  {
    id: 'consecutive_shifts',
    name: 'Consecutive Shifts',
  },
  {
    id: 'consecutive_days_off',
    name: 'Consecutive Days Off',
  },
  {
    id: 'weekend_distribution',
    name: 'Weekend Distribution',
  },
  {
    id: 'shift_rotation',
    name: 'Shift Rotation',
  },
  {
    id: 'shift_distribution',
    name: 'Shift Distribution',
  }
];
