import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Modal,
  Form
} from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import DropdownFilter from 'components/common/DropdownFilter';
import Flex from 'components/common/Flex';

const doctors = [
  { name: 'Dr. John Smith', shift: 'Day' },
  { name: 'Dr. Sarah Johnson', shift: 'Night' },
  { name: 'Dr. Michael Brown', shift: 'Day' },
  { name: 'Dr. Emily Davis', shift: 'Night' },
  { name: 'Dr. Robert Wilson', shift: 'Day' },
  { name: 'Dr. Lisa Anderson', shift: 'Night' },
  { name: 'Dr. David Lee', shift: 'Day' },
  { name: 'Dr. Maria Garcia', shift: 'Night' },
  { name: 'Dr. James Taylor', shift: 'Day' },
  { name: 'Dr. Jennifer White', shift: 'Night' }
];

const shiftTypes = ['Day', 'Night'];

const generateMockAssignments = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const assignments = [];

  // Get the first Sunday of the month or the first day if it's a Sunday
  let startDate = new Date(firstDay);
  startDate.setDate(firstDay.getDate() - firstDay.getDay());

  // Get the last Saturday of the month or the last day if it's a Saturday
  let endDate = new Date(lastDay);
  if (endDate.getDay() !== 6) {
    endDate.setDate(lastDay.getDate() + (6 - lastDay.getDay()));
  }

  // Generate assignments for each day
  let currentDay = new Date(startDate);
  while (currentDay <= endDate) {
    // Randomly select doctors for this day
    const selectedDoctors = doctors
      .sort(() => 0.5 - Math.random())
      .slice(0, Math.floor(Math.random() * 3) + 1);

    selectedDoctors.forEach(doctor => {
      const isDay = doctor.shift === 'Day';
      const shiftStart = new Date(currentDay);
      const shiftEnd = new Date(currentDay);
      
      if (isDay) {
        // Day shift: 07:00 to 15:00
        shiftStart.setHours(7, 0, 0);
        shiftEnd.setHours(15, 0, 0);
      } else {
        // Night shift: 15:00 to 23:00 (same day)
        shiftStart.setHours(15, 0, 0);
        shiftEnd.setHours(23, 0, 0);
      }

      const assignment = {
        title: `${isDay ? '7:00 AM ' : '3:00 PM '}${doctor.shift} Shift - ${doctor.name}`,
        start: shiftStart.toISOString(),
        end: shiftEnd.toISOString(),
        backgroundColor: doctor.shift === 'Day' ? '#00d27a' : '#27bcfd',
        textColor: '#fff',
        borderColor: 'transparent',
        allDay: false,
        extendedProps: {
          date: currentDay.toLocaleDateString(),
          doctors: selectedDoctors,
          doctor: doctor
        }
      };
      assignments.push(assignment);
    });

    currentDay.setDate(currentDay.getDate() + 1);
  }

  return assignments;
};

const CurrentRotation = () => {
  const {
    config: { isRTL }
  } = useAppContext();
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [assignments, setAssignments] = useState(generateMockAssignments());
  const [editMode, setEditMode] = useState(false);
  const [editedDoctor, setEditedDoctor] = useState(null);

  const viewName = [
    'Month View',
    'Week View',
    'Day View',
    'List View'
  ];

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  const handleEventClick = info => {
    setSelectedEvent(info.event);
    setShowEventModal(true);
    setEditMode(false);
  };

  const handleEditDoctor = (doctor) => {
    setEditedDoctor({
      ...doctor,
      originalName: doctor.name // Keep track of original name for updating
    });
    setEditMode(true);
  };

  const handleSaveEdit = () => {
    if (!editedDoctor) return;

    const updatedAssignments = assignments.map(assignment => {
      // Compare the exact event using start time and the specific doctor
      const assignmentDate = new Date(assignment.start);
      const selectedDate = new Date(selectedEvent.start);
      
      if (assignmentDate.getTime() === selectedDate.getTime() && 
          assignment.title === selectedEvent.title) {
            
        // Update the specific doctor in the doctors array
        const updatedDoctors = assignment.extendedProps.doctors.map(doctor => 
          doctor.name === editedDoctor.originalName ? editedDoctor : doctor
        );

        // Create new shift times based on the updated shift type
        const shiftDate = new Date(assignment.start);
        let newStart = new Date(shiftDate);
        let newEnd = new Date(shiftDate);

        if (editedDoctor.shift === 'Day') {
          newStart.setHours(7, 0, 0);
          newEnd.setHours(15, 0, 0);
        } else {
          newStart.setHours(15, 0, 0);
          newEnd.setHours(23, 0, 0);
        }

        return {
          ...assignment,
          title: `${editedDoctor.shift === 'Day' ? '7:00 AM ' : '3:00 PM '}${editedDoctor.shift} Shift - ${editedDoctor.name}`,
          start: newStart.toISOString(),
          end: newEnd.toISOString(),
          backgroundColor: editedDoctor.shift === 'Day' ? '#00d27a' : '#27bcfd',
          textColor: '#fff',
          borderColor: 'transparent',
          allDay: false,
          extendedProps: {
            ...assignment.extendedProps,
            doctors: updatedDoctors,
            doctor: editedDoctor
          }
        };
      }
      return assignment;
    });

    setAssignments(updatedAssignments);
    setEditMode(false);
    setEditedDoctor(null);
    setShowEventModal(false);
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-9 fs-sm-8 fs-lg-7">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0 app-calendar">
          <style>
            {`
              .fc-event-title {
                white-space: pre-line !important;
                overflow: visible !important;
              }
            `}
          </style>
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={4}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            events={assignments}
            eventClick={handleEventClick}
            initialDate={new Date()}
            slotDuration="01:00:00"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            nowIndicator={true}
            allDaySlot={false}
            editable={true}
            eventDisplay="block"
            displayEventEnd={true}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showEventModal}
        onHide={() => {
          setShowEventModal(false);
          setEditMode(false);
          setEditedDoctor(null);
        }}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">
              {editMode ? 'Edit Assignment' : `Assignments for ${selectedEvent?.extendedProps?.date}`}
            </h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!editMode ? (
            selectedEvent && selectedEvent.extendedProps.doctors.map((doctor, index) => (
              <Flex key={index} className="mb-3 justify-content-between align-items-center">
                <div className="flex-1">
                  <h6 className="mb-0">{doctor.name}</h6>
                  <div className="fs-9">
                    <span className="text-600">
                      <FontAwesomeIcon icon="clock" className="me-2" />
                      {doctor.shift}
                    </span>
                  </div>
                </div>
                <Button
                  variant="falcon-primary"
                  size="sm"
                  onClick={() => handleEditDoctor(doctor)}
                >
                  <FontAwesomeIcon icon="edit" className="me-1" />
                  Edit
                </Button>
              </Flex>
            ))
          ) : (
            <Form>
              <Form.Group className="mb-3">
                <Form.Label>Doctor</Form.Label>
                <Form.Select
                  value={editedDoctor?.name || ''}
                  onChange={(e) => {
                    const selectedDoctor = doctors.find(d => d.name === e.target.value);
                    setEditedDoctor({
                      ...editedDoctor,
                      name: selectedDoctor.name,
                      shift: selectedDoctor.shift
                    });
                  }}
                >
                  <option value="">Select a doctor</option>
                  {doctors.map((doctor) => (
                    <option key={doctor.name} value={doctor.name}>
                      {doctor.name}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <Form.Group className="mb-3">
                <Form.Label>Shift</Form.Label>
                <Form.Select
                  value={editedDoctor?.shift || ''}
                  onChange={(e) => setEditedDoctor({ ...editedDoctor, shift: e.target.value })}
                >
                  {shiftTypes.map((shift) => (
                    <option key={shift} value={shift}>
                      {shift}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Form>
          )}
        </Modal.Body>
        <Modal.Footer>
          {editMode ? (
            <>
              <Button variant="secondary" onClick={() => {
                setEditMode(false);
                setEditedDoctor(null);
              }}>
                Cancel
              </Button>
              <Button variant="primary" onClick={handleSaveEdit}>
                Save Changes
              </Button>
            </>
          ) : (
            <Button variant="secondary" onClick={() => setShowEventModal(false)}>
              Close
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
};

const renderEventContent = (eventInfo) => {
  const doctors = eventInfo.event.extendedProps.doctors;
  return {
    html: `<div style="display: flex; flex-direction: column;">
      ${doctors.slice(0, 2).map(doctor => 
        `<div style="line-height: 1.2;">${doctor.name}</div>`
      ).join('')}
      ${doctors.length > 2 ? `<div style="line-height: 1.2;">+${doctors.length - 2} more</div>` : ''}
    </div>`
  };
};

export default CurrentRotation; 