import React from 'react';
import { Row, Col, Card, Form, Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const ShiftsStep = ({ shifts, setShifts }) => {
  const addCustomShift = () => {
    const newShift = {
      id: `custom-${Date.now()}`,
      name: `Custom Shift ${shifts.filter(s => s.id.startsWith('custom-')).length + 1}`,
      label: 'Custom',
      enabled: true,
      start: '09:00',
      end: '17:00',
      minConsecutive: 2,
      maxConsecutive: 5,
      forbiddenSuccessors: [],
      penalizedSuccessors: [],
      penalty: 100
    };
    setShifts([...shifts, newShift]);
  };

  const deleteShift = (shiftId) => {
    setShifts(shifts.filter(shift => shift.id !== shiftId));
  };

  const handlePenaltyChange = (index, value) => {
    const numValue = parseInt(value);
    if (!isNaN(numValue) && numValue >= 100 && numValue <= 1000) {
      const updatedShifts = [...shifts];
      updatedShifts[index].penalty = numValue;
      setShifts(updatedShifts);
    }
  };

  const handleForbiddenChange = (index, value) => {
    const updatedShifts = [...shifts];
    updatedShifts[index].forbiddenSuccessors = value ? [value] : [];
    setShifts(updatedShifts);
  };

  const handlePenalizedChange = (index, value) => {
    const updatedShifts = [...shifts];
    updatedShifts[index].penalizedSuccessors = value ? [value] : [];
    if (!value) {
      updatedShifts[index].penalty = 100;
    }
    setShifts(updatedShifts);
  };

  const renderShiftCard = (shift, index) => (
    <Card className="h-100 mb-3 bg-transparent">
      <Card.Header className="bg-transparent border-0 py-2">
        <div className="d-flex align-items-center">
          <Form.Check
            type="checkbox"
            checked={shift.enabled}
            onChange={e => {
              const updatedShifts = [...shifts];
              updatedShifts[index].enabled = e.target.checked;
              setShifts(updatedShifts);
            }}
            className="me-2"
          />
          <h6 className="mb-0 flex-grow-1 text-500">{shift.name}</h6>
          <span className="shift-label ms-2 px-2 py-1 rounded text-primary d-flex align-items-center justify-content-center" style={{ minWidth: '24px', minHeight: '24px', backgroundColor: 'var(--falcon-200)' }}>
            <FontAwesomeIcon icon={`fa-solid fa-${shift.label.toLowerCase()}`} size="sm" />
          </span>
          {shift.id.startsWith('custom-') && (
            <Button
              variant="falcon-default"
              size="sm"
              className="ms-2 p-0 px-1"
              onClick={() => deleteShift(shift.id)}
            >
              <FontAwesomeIcon icon="trash-alt" className="text-danger fs--2" />
            </Button>
          )}
        </div>
      </Card.Header>
      {shift.id !== 'off' && (
        <Card.Body className="bg-transparent p-2">
          <Row className="g-2">
            {shift.id.startsWith('custom-') && (
              <Col xs={12}>
                <Form.Group>
                  <Form.Label className="mb-1 text-700">Name</Form.Label>
                  <Form.Control
                    size="sm"
                    type="text"
                    value={shift.name}
                    onChange={e => {
                      const updatedShifts = [...shifts];
                      updatedShifts[index].name = e.target.value;
                      setShifts(updatedShifts);
                    }}
                    disabled={!shift.enabled}
                    className="bg-transparent"
                  />
                </Form.Group>
              </Col>
            )}
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">Start</Form.Label>
                <Form.Control
                  size="sm"
                  type="time"
                  value={shift.start}
                  onChange={e => {
                    const updatedShifts = [...shifts];
                    updatedShifts[index].start = e.target.value;
                    setShifts(updatedShifts);
                  }}
                  disabled={!shift.enabled || shift.id === 'off' || !shift.id.startsWith('custom-')}
                  className="bg-transparent"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">End</Form.Label>
                <Form.Control
                  size="sm"
                  type="time"
                  value={shift.end}
                  onChange={e => {
                    const updatedShifts = [...shifts];
                    updatedShifts[index].end = e.target.value;
                    setShifts(updatedShifts);
                  }}
                  disabled={!shift.enabled || shift.id === 'off' || !shift.id.startsWith('custom-')}
                  className="bg-transparent"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">Min Days</Form.Label>
                <Form.Control
                  size="sm"
                  type="number"
                  min="1"
                  max="7"
                  value={shift.minConsecutive}
                  onChange={e => {
                    const updatedShifts = [...shifts];
                    updatedShifts[index].minConsecutive = parseInt(e.target.value);
                    setShifts(updatedShifts);
                  }}
                  disabled={!shift.enabled}
                  className="bg-transparent"
                />
              </Form.Group>
            </Col>
            <Col xs={6}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">Max Days</Form.Label>
                <Form.Control
                  size="sm"
                  type="number"
                  min="1"
                  max="7"
                  value={shift.maxConsecutive}
                  onChange={e => {
                    const updatedShifts = [...shifts];
                    updatedShifts[index].maxConsecutive = parseInt(e.target.value);
                    setShifts(updatedShifts);
                  }}
                  disabled={!shift.enabled}
                  className="bg-transparent"
                />
              </Form.Group>
            </Col>
            <Col xs={12}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">Forbidden After</Form.Label>
                <Form.Select
                  size="sm"
                  value={shift.forbiddenSuccessors[0] || ''}
                  onChange={e => handleForbiddenChange(index, e.target.value)}
                  disabled={!shift.enabled}
                  className="bg-transparent"
                >
                  <option value="">None</option>
                  {shifts.filter(s => s.id !== shift.id).map(s => (
                    <option key={s.id} value={s.id}>{s.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={8}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">Penalized After</Form.Label>
                <Form.Select
                  size="sm"
                  value={shift.penalizedSuccessors[0] || ''}
                  onChange={e => handlePenalizedChange(index, e.target.value)}
                  disabled={!shift.enabled}
                  className="bg-transparent"
                >
                  <option value="">None</option>
                  {shifts.filter(s => s.id !== shift.id).map(s => (
                    <option key={s.id} value={s.id}>{s.name}</option>
                  ))}
                </Form.Select>
              </Form.Group>
            </Col>
            <Col xs={4}>
              <Form.Group>
                <Form.Label className="mb-1 text-700">Penalty</Form.Label>
                <Form.Control
                  size="sm"
                  type="number"
                  min="100"
                  max="1000"
                  value={shift.penalty}
                  onChange={e => handlePenaltyChange(index, e.target.value)}
                  disabled={!shift.enabled || !shift.penalizedSuccessors.length}
                  className="bg-transparent"
                />
              </Form.Group>
            </Col>
          </Row>
        </Card.Body>
      )}
    </Card>
  );

  return (
    <Row>
      <Col md={12}>
        <div className="shifts-container">
          <Row className="g-3">
            {shifts.map((shift, index) => (
              <Col key={shift.id} md={6} lg={4} xl={3}>
                {renderShiftCard(shift, index)}
              </Col>
            ))}
            <Col md={6} lg={4} xl={3}>
              <Card 
                className="h-100 d-flex align-items-center justify-content-center bg-transparent cursor-pointer"
                onClick={addCustomShift}
                role="button"
                style={{ minHeight: '150px' }}
              >
                <div className="text-center p-3">
                  <FontAwesomeIcon icon="plus" className="text-primary fs-1 mb-2" />
                  <h6 className="mb-0">Add Custom Shift</h6>
                </div>
              </Card>
            </Col>
          </Row>
        </div>
      </Col>
    </Row>
  );
};

export default ShiftsStep; 