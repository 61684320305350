import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { unionOptions, defaultRules } from './WizardCommon';
import CustomRuleDialog from './CustomRuleDialog';

const UnionStep = ({
  selectedUnion,
  setSelectedUnion,
  selectedRules,
  setSelectedRules
}) => {
  const [showCustomRuleDialog, setShowCustomRuleDialog] = useState(false);
  const [customRules, setCustomRules] = useState([]);

  // Convert any numeric rules to the new format on component mount
  useEffect(() => {
    if (selectedRules.some(rule => typeof rule === 'number')) {
      const convertedRules = selectedRules.map(rule => {
        if (typeof rule === 'number' && rule <= defaultRules.length) {
          return defaultRules[rule - 1].id;
        }
        return rule;
      });
      setSelectedRules(convertedRules.filter(rule => typeof rule === 'string'));
    }
  }, []);

  const handleRuleChange = ruleId => {
    if (typeof setSelectedRules !== 'function') {
      console.error('setSelectedRules is not properly initialized');
      return;
    }
    setSelectedRules(prev => {
      const currentRules = Array.isArray(prev) ? prev : [];
      if (currentRules.includes(ruleId)) {
        return currentRules.filter(item => item !== ruleId);
      } else {
        return [...currentRules, ruleId];
      }
    });
  };

  const handleAddRule = () => {
    setShowCustomRuleDialog(true);
  };

  const handleAddCustomRule = (ruleData) => {
    const newRule = {
      id: `custom_${customRules.length + 1}`,
      name: ruleData.name || `Custom Rule ${customRules.length + 1}`,
      description: ruleData.description,
      example: ruleData.example
    };
    setCustomRules(prev => [...prev, newRule]);
    // Automatically select the new custom rule
    handleRuleChange(newRule.id);
  };

  // Combine default rules with custom rules
  const allRules = [...defaultRules, ...customRules];

  return (
    <div className="h-100">
      <Row className="h-100">
        <Col md={3}>
          <div className="border-end h-100" style={{ overflowY: 'auto' }}>
            <h5 className="mb-3">Collective Agreement</h5>
            <Form>
              {unionOptions.map(option => (
                <Form.Check
                  key={option.value}
                  type="radio"
                  id={option.value}
                  label={option.label}
                  checked={selectedUnion === option.value}
                  onChange={() => setSelectedUnion(option.value)}
                  className="mb-2"
                />
              ))}
            </Form>
          </div>
        </Col>
        <Col md={9}>
          <div className="h-100 d-flex flex-column">
            <div className="d-flex justify-content-between align-items-center mb-3">
              <h5 className="mb-0">Rules</h5>
              <Button
                variant="falcon-primary"
                size="sm"
                onClick={handleAddRule}
                className="d-flex align-items-center"
              >
                <span className="fas fa-plus fs--2 me-2"></span>
                Add New Rule
              </Button>
            </div>
            <div className="flex-grow-1" style={{ overflowY: 'auto' }}>
              {allRules.map(rule => (
                <div 
                  key={rule.id} 
                  className={`rule-item mb-3 p-3 border rounded ${
                    selectedRules.includes(rule.id) ? 'border-primary bg-light' : ''
                  }`}
                >
                  <div className="d-flex align-items-center mb-2">
                    <Form.Check
                      type="checkbox"
                      id={`rule-${rule.id}`}
                      checked={selectedRules.includes(rule.id)}
                      onChange={() => handleRuleChange(rule.id)}
                      className="me-3"
                    />
                    <h6 className="mb-0 fw-bold">{rule.name}</h6>
                  </div>
                  <div className="ms-4">
                    {rule.description ? (
                      <>
                        <p className="mb-2">
                          <span className="fw-semibold">Description:</span><br/>
                          {rule.description}
                        </p>
                        {rule.example && (
                          <p className="mb-0">
                            <span className="fw-semibold">Example:</span><br/>
                            {rule.example}
                          </p>
                        )}
                      </>
                    ) : (
                      <p className="text-muted mb-0">
                        Default rule for {rule.name.toLowerCase()}.
                      </p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Col>
      </Row>
      
      <CustomRuleDialog
        show={showCustomRuleDialog}
        onHide={() => setShowCustomRuleDialog(false)}
        onAdd={handleAddCustomRule}
      />
    </div>
  );
};

UnionStep.propTypes = {
  selectedUnion: PropTypes.string,
  setSelectedUnion: PropTypes.func.isRequired,
  selectedRules: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number // Including number temporarily for migration
  ])),
  setSelectedRules: PropTypes.func.isRequired
};

UnionStep.defaultProps = {
  selectedUnion: '',
  selectedRules: []
};

export default UnionStep;
