import React, { useState } from 'react';
import { Form, Button, Row, Col, Card } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

const RequestsStep = ({ requests, setRequests }) => {
  const [newRequest, setNewRequest] = useState('');
  const [newRequests, setNewRequests] = useState([]);
  const [existingRequests, setExistingRequests] = useState([]);

  const handleRequestChange = (index) => {
    const updatedRequests = [...existingRequests];
    updatedRequests[index].selected = !updatedRequests[index].selected;
    setExistingRequests(updatedRequests);
  };

  const handleAddRequest = (e) => {
    e.preventDefault();
    if (newRequest.trim()) {
      const newRequestObj = {
        id: `new-${newRequests.length + 1}`,
        text: newRequest,
        selected: true
      };
      setNewRequests([...newRequests, newRequestObj]);
      setNewRequest('');
    }
  };

  const handleNewRequestChange = (index) => {
    const updatedNewRequests = [...newRequests];
    updatedNewRequests[index].selected = !updatedNewRequests[index].selected;
    setNewRequests(updatedNewRequests);
  };

  const defaultRequests = [
    {
      id: 1,
      text: 'Employee 1 requested Off Shift on Sundays.',
      selected: false
    },
    {
      id: 2,
      text: 'Employee 2 requested not to have Evening Shift on Mondays.',
      selected: false
    },
    {
      id: 3,
      text: 'Employee 3 requested to have September 12th off.',
      selected: false
    }
  ];

  // Initialize existing requests if empty
  React.useEffect(() => {
    if (existingRequests.length === 0) {
      setExistingRequests(defaultRequests);
    }
  }, []);

  // Update parent requests when either existing or new requests change
  React.useEffect(() => {
    const selectedExisting = existingRequests.filter(r => r.selected);
    const selectedNew = newRequests.filter(r => r.selected);
    setRequests([...selectedExisting, ...selectedNew]);
  }, [existingRequests, newRequests]);

  return (
    <div className="h-100 d-flex flex-column" style={{ minHeight: 0 }}>
      {/* Top list */}
      <Card className="mb-3" style={{ height: '25vh' }}>
        <Card.Header className="bg-light border-bottom">
          <h5 className="mb-0 text-900">Existing Requests</h5>
        </Card.Header>
        <Card.Body className="p-0" style={{ height: 'calc(25vh - 49px)' }}>
          <SimpleBar style={{ height: '100%' }}>
            <div className="p-3">
              {existingRequests.map((request, index) => (
                <Form.Check
                  key={request.id}
                  type="checkbox"
                  id={`request-${request.id}`}
                  label={request.text}
                  checked={request.selected}
                  onChange={() => handleRequestChange(index)}
                  className="mb-2"
                />
              ))}
            </div>
          </SimpleBar>
        </Card.Body>
      </Card>

      {/* Bottom list */}
      <Card className="mb-0" style={{ height: '32vh' }}>
        <Card.Header className="bg-light border-bottom">
          <h5 className="mb-0 text-900">Add New Request</h5>
        </Card.Header>
        <Card.Body className="p-0 d-flex flex-column">
          <div className="p-3">
            <Form onSubmit={handleAddRequest}>
              <Row className="g-3 mb-2">
                <Col>
                  <Form.Control
                    type="text"
                    placeholder="Enter new request..."
                    value={newRequest}
                    onChange={(e) => setNewRequest(e.target.value)}
                    className="form-control-light"
                  />
                </Col>
                <Col xs="auto">
                  <Button 
                    type="submit" 
                    variant="falcon-primary"
                    disabled={!newRequest.trim()}
                  >
                    Add Request
                  </Button>
                </Col>
              </Row>
            </Form>
          </div>

          <div className="border-top flex-grow-1" style={{ height: 'calc(32vh - 140px)' }}>
            <SimpleBar style={{ height: '100%' }}>
              <div className="p-3">
                <h6 className="text-900 mb-2">New Requests:</h6>
                {newRequests.map((request, index) => (
                  <Form.Check
                    key={request.id}
                    type="checkbox"
                    id={`new-request-${request.id}`}
                    label={request.text}
                    checked={request.selected}
                    onChange={() => handleNewRequestChange(index)}
                    className="mb-2"
                  />
                ))}
              </div>
            </SimpleBar>
          </div>
        </Card.Body>
      </Card>
    </div>
  );
};

export default RequestsStep; 