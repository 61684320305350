import React from 'react';
import { Card, Row, Col, Button } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import AdvanceTable from 'components/common/advance-table/AdvanceTable';
import AdvanceTableSearchBox from 'components/common/advance-table/AdvanceTableSearchBox';
import AdvanceTableFooter from 'components/common/advance-table/AdvanceTableFooter';
import useAdvanceTable from 'hooks/useAdvanceTable';
import AdvanceTableProvider from 'providers/AdvanceTableProvider';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import SimpleBar from 'simplebar-react';
import paths from 'routes/paths';

const columns = [
  {
    accessorKey: 'dateCreated',
    header: 'Date Created',
    meta: {
      headerProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'startDate',
    header: 'Start Date',
    meta: {
      headerProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'endDate',
    header: 'End Date',
    meta: {
      headerProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'department',
    header: 'Department',
    meta: {
      headerProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'team',
    header: 'Team',
    meta: {
      headerProps: { className: 'text-900' }
    }
  },
  {
    accessorKey: 'admin',
    header: 'Admin',
    meta: {
      headerProps: { className: 'text-900' }
    }
  },
  {
    id: 'actions',
    header: 'Actions',
    cell: ({ row }) => (
      <Link to={paths.viewRotation(row.original.id)}>
        <Button variant="falcon-default" size="sm">
          <FontAwesomeIcon icon="eye" className="me-1" />
          View
        </Button>
      </Link>
    ),
    meta: {
      headerProps: { className: 'text-900' }
    }
  }
];

// Mock data for demonstration
const mockData = [
  {
    id: 1,
    dateCreated: '2024-01-15',
    startDate: '2024-02-01',
    endDate: '2024-02-28',
    department: 'Emergency',
    team: 'Night Shift',
    admin: 'John Doe'
  },
  {
    id: 2,
    dateCreated: '2024-01-16',
    startDate: '2024-02-15',
    endDate: '2024-03-15',
    department: 'Surgery',
    team: 'Day Shift',
    admin: 'Jane Smith'
  },
  {
    id: 3,
    dateCreated: '2024-01-17',
    startDate: '2024-03-01',
    endDate: '2024-03-31',
    department: 'Pediatrics',
    team: 'Evening Shift',
    admin: 'Mike Johnson'
  }
];

const FormTitle = ({ icon, title }) => {
  return (
    <Flex className="mb-3">
      <span className="fa-stack me-2 ms-n1">
        <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon={icon}
          className="fa-inverse fa-stack-1x text-primary"
        />
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
        </h5>
      </div>
    </Flex>
  );
};

const RotationsList = () => {
  const table = useAdvanceTable({
    data: mockData,
    columns,
    sortable: true,
    pagination: true,
    perPage: 10
  });

  return (
    <Row className="g-3">
      <Col lg={12} xl={12} xxl={12}>
        <FormTitle icon="list" title="Rotations List" />
        <Card className="theme-wizard">
          <Card.Header className="bg-body-tertiary">
            <Row className="flex-between-center">
              <Col xs="auto" sm={6} lg={7}>
                <h5 className="mb-0">Rotations List</h5>
              </Col>
              <Col xs="auto" sm={6} lg={5}>
                <AdvanceTableProvider {...table}>
                  <AdvanceTableSearchBox placeholder="Search rotations..." />
                </AdvanceTableProvider>
              </Col>
            </Row>
          </Card.Header>
          <Card.Body className="fw-normal px-md-6 py-4" style={{ height: 'calc(100vh - 320px)', overflow: 'hidden' }}>
            <SimpleBar style={{ height: '100%' }}>
              <AdvanceTableProvider {...table}>
                <AdvanceTable
                  headerClassName="bg-200 text-900 text-nowrap align-middle"
                  rowClassName="align-middle white-space-nowrap"
                  tableProps={{
                    bordered: true,
                    striped: true,
                    className: 'fs-10 mb-0 overflow-hidden'
                  }}
                />
              </AdvanceTableProvider>
            </SimpleBar>
          </Card.Body>
          <Card.Footer className="px-md-6 bg-body-tertiary">
            <AdvanceTableProvider {...table}>
              <AdvanceTableFooter
                rowInfo
                navButtons
                rowsPerPageSelection
              />
            </AdvanceTableProvider>
          </Card.Footer>
        </Card>
      </Col>
    </Row>
  );
};

FormTitle.propTypes = {
  icon: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
};

export default RotationsList; 