import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { version } from 'config';

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <Row className="justify-content-between text-center fs-10 mt-4 mb-3">
        <Col xs="auto">
          <p className="mb-0 text-600">All rights reserved | {currentYear} © Daric Clouding</p>
        </Col>
        <Col xs="auto">
          <p className="mb-0 text-600">v{version}</p>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
