// Mock data for rotations with consistent data across all components

const generateCalendarEvents = (days, staff) => {
  return days.flatMap(day => 
    day.shifts
      .filter(shift => shift.label !== '-')
      .map(shift => {
        const staffMember = staff.find(s => s.number === shift.staffId);
        const shiftColors = {
          D: { bg: '#00d27a', text: '#fff' },
          N: { bg: '#27bcfd', text: '#fff' },
          E: { bg: '#f5803e', text: '#fff' }
        };
        
        return {
          title: `${shift.label === 'D' ? '7:00 AM ' : ''}${
            shift.label === 'N' ? 'Night' : 
            shift.label === 'D' ? 'Day' : 'Evening'
          } Shift - ${staffMember.name}`,
          start: day.date,
          end: shift.label === 'N' ? 
            new Date(new Date(day.date).setDate(new Date(day.date).getDate() + 1)).toISOString() : 
            day.date,
          backgroundColor: shiftColors[shift.label].bg,
          textColor: shiftColors[shift.label].text,
          borderColor: 'transparent',
          allDay: true,
          staffId: staffMember.number,
          staffName: staffMember.name,
          shiftType: shift.label
        };
      })
  );
};

// Helper function to create a 10-day schedule
const createARandomDaySchedule = (startDate, staffCount, shiftPattern,totalDays) => {
  const days = [];  

  for (let day = 0; day < totalDays; day++) {
    const date = new Date(startDate);
    date.setDate(date.getDate() + day);
    const isWeekend = date.getDay() === 0 || date.getDay() === 6;
    
    // For each day, create shift assignments based on the pattern
    const shifts = [];
    for (let staffIndex = 0; staffIndex < staffCount; staffIndex++) {
      const shiftType = shiftPattern[staffIndex % shiftPattern.length];
      shifts.push({
        label: shiftType,
        staffId: staffIndex + 1
      });
    }

    days.push({
      number: day + 1,
      date: date.toISOString(),
      isWeekend,
      shifts
    });
  }

  return days;
};

// Define shift patterns for each department
const emergencyPattern = ['D', 'E', 'N', 'E', 'E', 'E', 'N', 'N', '-', '-', '-', '-', '-', '-', '-'];
const surgeryPattern = ['D', 'D', 'N', 'D', 'E', 'E', '-', '-', '-', '-'];
const pediatricsPattern = ['N', 'E', 'F', 'N', 'N', '-', '-', '-'];

// Helper function to calculate metrics from days data
const calculateMetrics = (days) => {
  let totalShifts = 0;
  let eveningShifts = 0;
  let nightShifts = 0;
  let weekendShifts = 0;

  days.forEach(day => {
    day.shifts.forEach(shift => {
      if (shift.label !== '-') {
        totalShifts++;
        
        if (shift.label === 'E') {
          eveningShifts++;
        } else if (shift.label === 'N') {
          nightShifts++;
        }
        
        if (day.isWeekend) {
          weekendShifts++;
        }
      }
    });
  });

  return {
    totalShifts,
    eveningShifts,
    nightShifts,
    weekendShifts
  };
};

// Helper function to parse date strings
const parseDate = (dateString) => {
  const months = {
    'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
    'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11
  };
  
  const [month, day, year] = dateString.match(/([A-Za-z]+)\s+(\d+)[a-z]{2},\s+(\d+)/).slice(1);
  return new Date(parseInt(year), months[month], parseInt(day));
};

// Create base rotation objects first
const baseRotations = {
  1: {
    // Basic Info
    id: 1,
    name: 'Emergency Department Rotation',
    department: 'Emergency',
    team: 'Night Shift',
    status: 'active',
    totalDays: 10,

    // Planning Details
    planningHorizon: 2,
    startDate: 'March 1st, 2025',
    endDate: 'June 21st, 2025',
    admin: 'Dr. Boby Grewal (bgrewal@providencehealth.bc.ca)',
    
    // Creation Details
    dateCreated: 'April 15th, 2024',
    approvedDate: 'April 20th, 2024',
    lastModified: 'April 20th, 2024',
    
    // Staff Details
    fullTimePositions: 3,
    partTimePositions: 1,
    staff: [
      { number: 1, name: 'Dr. Liam Tremblay', type: 'Full Time', email: 'l.tremblay@hospital.ca' },
      { number: 2, name: 'Dr. Emma Roy', type: 'Full Time', email: 'e.roy@hospital.ca' },
      { number: 3, name: 'Dr. Noah Bouchard', type: 'Full Time', email: 'n.bouchard@hospital.ca' },
      { number: 4, name: 'Dr. Alexander Lee', type: 'Part Time', email: 'a.lee@hospital.ca' }
    ],

    // Shift Types and Coverage
    shiftTypes: ['Day (D)', 'Night (N)', 'Evening (E)', 'Stat (st)', 'Off (~)'],
    
    // Staff Requests for the 2-week period
    requests: [
      { 
        date: '2025-03-01', 
        type: 'Day', 
        staff: 1,
        staffName: 'Dr. Liam Tremblay',
        status: 'approved',
        notes: 'Family event'
      },
      { 
        date: '2025-03-02', 
        type: 'Night', 
        staff: 2,
        staffName: 'Dr. Emma Roy',
        status: 'pending',
        notes: 'Medical appointment'
      }
    ],

    rules: {
      minRestBetweenShifts: 11,
      maxConsecutiveShifts: 5,
      maxNightShifts: 4,
      minShiftsPerMonth: 15,
      maxShiftsPerMonth: 22
    }
  },

  2: {
    id: 2,
    name: 'Surgery Day Shift Rotation',
    department: 'Surgery',
    team: 'Day Shift',
    status: 'active',
    totalDays: 10,

    planningHorizon: 1,
    startDate: 'April 1st, 2025',
    endDate: 'June 30th, 2025',
    admin: 'Dr. Sarah Thompson (s.thompson@providencehealth.bc.ca)',
    
    dateCreated: 'March 15th, 2024',
    approvedDate: 'March 20th, 2024',
    lastModified: 'March 20th, 2024',
    
    fullTimePositions: 2,
    partTimePositions: 1,
    staff: [
      { number: 1, name: 'Dr. Thomas Anderson', type: 'Full Time', email: 't.anderson@hospital.ca' },
      { number: 2, name: 'Dr. Marie Dubois', type: 'Full Time', email: 'm.dubois@hospital.ca' },
      { number: 3, name: 'Dr. Ryan Murphy', type: 'Part Time', email: 'r.murphy@hospital.ca' }
    ],

    shiftTypes: ['Day (D)', 'Evening (E)', 'Off (~)'],
    
    // Staff Requests for the 2-week period
    requests: [
      { 
        date: '2025-04-01', 
        type: 'Day', 
        staff: 1,
        staffName: 'Dr. Thomas Anderson',
        status: 'approved',
        notes: 'Conference'
      }
    ],

    rules: {
      minRestBetweenShifts: 11,
      maxConsecutiveShifts: 5,
      minShiftsPerMonth: 12,
      maxShiftsPerMonth: 18
    }
  },

  3: {
    id: 3,
    name: 'Pediatrics Evening Shift Rotation',
    department: 'Pediatrics',
    team: 'Evening Shift',
    status: 'active',
    totalDays: 10,

    planningHorizon: 2,
    startDate: 'May 1st, 2025',
    endDate: 'June 30th, 2025',
    admin: 'Dr. Jennifer Clark (j.clark@providencehealth.bc.ca)',
    
    dateCreated: 'April 1st, 2024',
    approvedDate: 'April 5th, 2024',
    lastModified: 'April 5th, 2024',
    
    fullTimePositions: 1,
    partTimePositions: 1,
    staff: [
      { number: 1, name: 'Dr. Rachel Green', type: 'Full Time', email: 'r.green@hospital.ca' },
      { number: 2, name: 'Dr. Laura Martinez', type: 'Part Time', email: 'l.martinez@hospital.ca' }
    ],

    shiftTypes: ['Evening (E)', 'Night (N)', 'Off (~)'],
    
    // Staff Requests for the 2-week period
    requests: [
      { 
        date: '2025-05-01', 
        type: 'Evening', 
        staff: 1,
        staffName: 'Dr. Rachel Green',
        status: 'approved',
        notes: 'Personal'
      }
    ],

    rules: {
      minRestBetweenShifts: 11,
      maxConsecutiveShifts: 4,
      minShiftsPerMonth: 10,
      maxShiftsPerMonth: 15
    }
  }
};

// Initialize the rotations with days and metrics
export const mockRotations = Object.entries(baseRotations).reduce((acc, [id, rotation]) => {
  // Create a new rotation object with all the base properties
  acc[id] = {
    ...rotation,
    // Generate days using the rotation's properties
    days: createARandomDaySchedule(
      parseDate(rotation.startDate),
      rotation.staff.length,
      id === '1' ? emergencyPattern : 
      id === '2' ? surgeryPattern : 
      pediatricsPattern,
      rotation.totalDays
    ),
    // Add the metrics getter
    get metrics() {
      return calculateMetrics(this.days);
    }
  };
  return acc;
}, {});

// Generate calendar events for each rotation
Object.keys(mockRotations).forEach(rotationId => {
  const rotation = mockRotations[rotationId];
  rotation.calendarEvents = generateCalendarEvents(rotation.days, rotation.staff);
});

export default mockRotations; 