import paths, { rootPaths } from './paths';

export const adminAreaRoutes = {
  label: 'Admin Area',
  children: [
    {
      name: 'Current Rotation',
      active: true,
      icon: 'calendar-alt',
      to: rootPaths.root,
      exact: true
    },
    {
      name: 'Create Rotation',
      active: true,
      icon: 'plus',
      to: paths.newRotation,
      exact: true
    },
    {
      name: 'Review Requests',
      active: true,
      icon: 'tasks',
      to: paths.reviewRequests,
      exact: true
    },
    {
      name: 'Rotations List',
      active: true,
      icon: 'list',
      to: paths.rotationsList,
      exact: true
    }
  ]
};

export const userAreaRoutes = {
  label: 'User Area',
  children: [
    {
      name: 'My Calendar',
      icon: 'calendar-alt',
      to: paths.userCalendar,
      active: true
    },
    {
      name: 'Assignment Requests',
      icon: 'tasks',
      to: paths.userRequests,
      active: true
    }
  ]
};

export default [adminAreaRoutes, userAreaRoutes];
