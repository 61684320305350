import React, { useState } from 'react';
import { Row, Col, Form, Button, Card } from 'react-bootstrap';

const StaffStep = ({ staffing, setStaffing }) => {
  const [newCategoryName, setNewCategoryName] = useState('');

  const handleAddCategory = () => {
    if (newCategoryName.trim()) {
      setStaffing(prev => ({
        ...prev,
        [newCategoryName.toLowerCase().replace(/\s+/g, '')]: {
          count: 0,
          minHours: 0,
          maxHours: 0
        }
      }));
      setNewCategoryName('');
    }
  };

  const handleValueChange = (category, field, value) => {
    setStaffing(prev => ({
      ...prev,
      [category]: {
        ...prev[category],
        [field]: parseInt(value) || 0
      }
    }));
  };

  const renderEmployeeRow = (label, category) => (
    <tr>
      <td>
        <strong>{label}:</strong>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <Form.Control
            type="number"
            value={staffing[category]?.count || 0}
            onChange={e => handleValueChange(category, 'count', e.target.value)}
            min={0}
            className="form-control-sm input-spin-none"
            style={{ width: '100px' }}
          />
          <div className="falcon-button-group ms-2">
            <Button
              variant="falcon-default"
              size="sm"
              className="px-2 border-300"
              onClick={() => handleValueChange(category, 'count', (staffing[category]?.count || 0) - 1)}
            >
              -
            </Button>
            <Button
              variant="falcon-default"
              size="sm"
              className="px-2 border-300"
              onClick={() => handleValueChange(category, 'count', (staffing[category]?.count || 0) + 1)}
            >
              +
            </Button>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <Form.Control
            type="number"
            value={staffing[category]?.minHours || 0}
            onChange={e => handleValueChange(category, 'minHours', e.target.value)}
            min={0}
            className="form-control-sm input-spin-none"
            style={{ width: '100px' }}
          />
          <div className="falcon-button-group ms-2">
            <Button
              variant="falcon-default"
              size="sm"
              className="px-2 border-300"
              onClick={() => handleValueChange(category, 'minHours', (staffing[category]?.minHours || 0) - 1)}
            >
              -
            </Button>
            <Button
              variant="falcon-default"
              size="sm"
              className="px-2 border-300"
              onClick={() => handleValueChange(category, 'minHours', (staffing[category]?.minHours || 0) + 1)}
            >
              +
            </Button>
          </div>
        </div>
      </td>
      <td>
        <div className="d-flex align-items-center">
          <Form.Control
            type="number"
            value={staffing[category]?.maxHours || 0}
            onChange={e => handleValueChange(category, 'maxHours', e.target.value)}
            min={0}
            className="form-control-sm input-spin-none"
            style={{ width: '100px' }}
          />
          <div className="falcon-button-group ms-2">
            <Button
              variant="falcon-default"
              size="sm"
              className="px-2 border-300"
              onClick={() => handleValueChange(category, 'maxHours', (staffing[category]?.maxHours || 0) - 1)}
            >
              -
            </Button>
            <Button
              variant="falcon-default"
              size="sm"
              className="px-2 border-300"
              onClick={() => handleValueChange(category, 'maxHours', (staffing[category]?.maxHours || 0) + 1)}
            >
              +
            </Button>
          </div>
        </div>
      </td>
    </tr>
  );

  return (
    <Row>
      <Col md={12}>
        <Card>
          <Card.Body>
            <Form className="staff-form">
              <table className="table">
                <thead>
                  <tr>
                    <th></th>
                    <th>Number of Employees</th>
                    <th>Minimum Hours/Week</th>
                    <th>Maximum Hours/Week</th>
                  </tr>
                </thead>
                <tbody>
                  {renderEmployeeRow('Number of Full Time Employees', 'fullTime')}
                  {renderEmployeeRow('Number of Part Time Employees', 'partTime')}
                  {renderEmployeeRow('Number of Casual Employees', 'casual')}
                  {Object.keys(staffing)
                    .filter(key => !['fullTime', 'partTime', 'casual'].includes(key))
                    .map(category => renderEmployeeRow(
                      `Number of ${category.charAt(0).toUpperCase() + category.slice(1)} Employees`,
                      category
                    ))}
                </tbody>
              </table>
            </Form>
            <div className="mt-3">
              <Form.Group className="d-flex align-items-center">
                <Form.Control
                  type="text"
                  placeholder="Enter new category name"
                  value={newCategoryName}
                  onChange={e => setNewCategoryName(e.target.value)}
                  style={{ maxWidth: '300px' }}
                />
                <Button
                  variant="falcon-primary"
                  size="sm"
                  className="ms-2"
                  onClick={handleAddCategory}
                >
                  Add Category
                </Button>
              </Form.Group>
            </div>
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default StaffStep; 