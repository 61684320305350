import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'react-bootstrap';
import SimpleBar from 'simplebar-react';

const ViewRotation = ({ rotation }) => {
  const daysOfWeek = ['M', 'T', 'W', 'T', 'F', 'S', 'S'];
  const CELL_WIDTH = 45;
  const WEEK_WIDTH = CELL_WIDTH * 7;
  const LABEL_WIDTH = 60;

  const cellStyle = {
    width: `${CELL_WIDTH}px`,
    minWidth: `${CELL_WIDTH}px`,
    maxWidth: `${CELL_WIDTH}px`,
    padding: 0,
    boxSizing: 'border-box',
    overflow: 'hidden',
    height: '40px',
    lineHeight: '40px',
    textAlign: 'center'
  };

  const labelColumnStyle = {
    width: `${LABEL_WIDTH}px`,
    minWidth: `${LABEL_WIDTH}px`,
    maxWidth: `${LABEL_WIDTH}px`,
    zIndex: 2,
    left: 0,
    backgroundColor: 'var(--falcon-white)',
    position: 'sticky',
    textAlign: 'center'
  };

  const numberOfWeeks = Math.ceil(rotation.days.length / 7);
  const totalDays = rotation.days.length;

  const renderDayNumbers = (weekIndex) => {
    return daysOfWeek.map((_, dayIndex) => {
      const dayNumber = weekIndex * 7 + dayIndex + 1;
      if (dayNumber > totalDays) return null;
      const isWeekend = dayIndex === 5 || dayIndex === 6;
      return (
        <th 
          key={`number-${weekIndex}-${dayIndex}`}
          className={`text-center p-0 ${isWeekend ? 'bg-warning-subtle' : ''}`}
          style={{ 
            width: CELL_WIDTH,
            minWidth: CELL_WIDTH,
            maxWidth: CELL_WIDTH
          }}
        >
          <div className="fs-11 text-600">{dayNumber}</div>
        </th>
      );
    }).filter(Boolean);
  };

  const renderWeekHeader = (weekIndex) => {
    return daysOfWeek.map((day, dayIndex) => {
      const dayNumber = weekIndex * 7 + dayIndex + 1;
      if (dayNumber > totalDays) return null;
      const isWeekend = dayIndex === 5 || dayIndex === 6;
      return (
        <th 
          key={`day-${weekIndex}-${dayIndex}`}
          className={`text-center p-1 ${isWeekend ? 'bg-warning-subtle' : ''}`}
          style={{ 
            width: CELL_WIDTH,
            minWidth: CELL_WIDTH,
            maxWidth: CELL_WIDTH
          }}
        >
          {day}
        </th>
      );
    }).filter(Boolean);
  };

  const renderShiftCell = (dayIndex, staffIndex) => {
    const day = rotation.days[dayIndex];
    if (!day) return null;

    const isWeekend = dayIndex % 7 >= 5;
    const shift = day.shifts?.[staffIndex] || { label: '-' };
    const shiftLabel = shift.label || '-';
    
    return (
      <td 
        key={dayIndex} 
        className={isWeekend ? 'bg-warning-subtle' : ''} 
        style={cellStyle}
      >
        {shiftLabel}
      </td>
    );
  };

  return (
    <SimpleBar>
      <div className="p-3">
        <div style={{ 
          width: `${LABEL_WIDTH + (totalDays * CELL_WIDTH)}px`,
          minWidth: `${LABEL_WIDTH + (totalDays * CELL_WIDTH)}px`,
          overflowX: 'auto'
        }}>
          <Table 
            bordered 
            hover 
            size="sm" 
            className="mb-0" 
            style={{ 
              tableLayout: 'fixed',
              width: `${LABEL_WIDTH + (totalDays * CELL_WIDTH)}px`,
              borderCollapse: 'separate',
              borderSpacing: 0
            }}
          >
            <colgroup>
              <col style={{ width: `${LABEL_WIDTH}px` }} />
              {Array.from({ length: totalDays }, (_, index) => (
                <col key={index} style={{ width: `${CELL_WIDTH}px` }} />
              ))}
            </colgroup>
            
            <thead>
              <tr>
                <th style={labelColumnStyle}>&nbsp;</th>
                {[...Array(Math.ceil(totalDays / 7))].map((_, weekIndex) => (
                  <React.Fragment key={`numbers-${weekIndex}`}>
                    {renderDayNumbers(weekIndex)}
                  </React.Fragment>
                ))}
              </tr>
              <tr>
                <th style={labelColumnStyle}>&nbsp;</th>
                {[...Array(Math.ceil(totalDays / 7))].map((_, weekIndex) => (
                  <React.Fragment key={`days-${weekIndex}`}>
                    {renderWeekHeader(weekIndex)}
                  </React.Fragment>
                ))}
              </tr>
            </thead>
            <tbody>
              {rotation.staff.map((staff, staffIndex) => (
                <tr key={staffIndex}>
                  <td style={labelColumnStyle} className="text-900">
                    {staff.number}
                  </td>
                  {Array.from({ length: totalDays }, (_, dayIndex) => 
                    renderShiftCell(dayIndex, staffIndex)
                  )}
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </div>
    </SimpleBar>
  );
};

ViewRotation.propTypes = {
  rotation: PropTypes.shape({
    staff: PropTypes.arrayOf(PropTypes.shape({
      number: PropTypes.number.isRequired
    })).isRequired,
    days: PropTypes.arrayOf(PropTypes.shape({
      shifts: PropTypes.arrayOf(PropTypes.shape({
        label: PropTypes.string
      }))
    })).isRequired
  }).isRequired
};

export default ViewRotation; 