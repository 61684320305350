import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { Card, Row, Col, Button, OverlayTrigger, Tooltip, Modal, Table } from 'react-bootstrap';
import ViewRotation from './ViewRotation';
import Flex from 'components/common/Flex';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FalconLink from 'components/common/FalconLink';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';
import listPlugin from '@fullcalendar/list';
import { useAppContext } from 'providers/AppProvider';
import DropdownFilter from 'components/common/DropdownFilter';
import { mockRotations } from 'data/mock/rotations';

// Helper function to get random integer between min and max (inclusive)
const getRandomInt = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

// Helper function to parse date string to ISO format
const parseDate = (dateStr) => {
  const months = {
    'January': 0, 'February': 1, 'March': 2, 'April': 3, 'May': 4, 'June': 5,
    'July': 6, 'August': 7, 'September': 8, 'October': 9, 'November': 10, 'December': 11
  };
  
  // Parse date string like "March 1st, 2025"
  const match = dateStr.match(/(\w+)\s+(\d+)\w+,\s+(\d+)/);
  if (match) {
    const [_, month, day, year] = match;
    return new Date(parseInt(year), months[month], parseInt(day));
  }
  return new Date(dateStr); // Fallback for ISO format strings
};

// Generate calendar events from rotation days
const generateCalendarEvents = (rotation) => {
  const shiftColors = {
    D: { bg: '#00d27a', text: '#fff' }, // Day shift - green
    N: { bg: '#27bcfd', text: '#fff' }, // Night shift - blue
    E: { bg: '#f5803e', text: '#fff' }  // Evening shift - orange
  };

  return rotation.days.flatMap(day => {
    return day.shifts
      .filter(shift => shift.label !== '-') // Skip off days
      .map(shift => {
        const staffMember = rotation.staff.find(s => s.number === shift.staffId);
        if (!staffMember) return null;

        const shiftDate = new Date(day.date);
        const endDate = new Date(shiftDate);
        
        // For night shifts, end date is next day
        if (shift.label === 'N') {
          endDate.setDate(endDate.getDate() + 1);
        }

        const shiftTypes = {
          D: 'Day',
          N: 'Night',
          E: 'Evening'
        };

        return {
          title: `${shift.label === 'D' ? '7:00 AM ' : ''}${shiftTypes[shift.label]} Shift - ${staffMember.name}`,
          start: shiftDate.toISOString(),
          end: endDate.toISOString(),
          backgroundColor: shiftColors[shift.label]?.bg || '#6c757d',
          textColor: shiftColors[shift.label]?.text || '#fff',
          borderColor: 'transparent',
          allDay: true,
          staffId: staffMember.number,
          staffName: staffMember.name,
          shiftType: shift.label
        };
      })
      .filter(Boolean); // Remove any null events
  });
};

// Static shift assignments
const generateStaticShiftAssignments = (startDate, endDate, staffCount) => {
  const assignments = [];
  const shiftTypes = [
    { type: 'Night Shift', color: '#27bcfd', textColor: '#fff' }
  ];

  // Sample pattern for night shifts
  const nightShiftPattern = [
    { startDay: 3, duration: 2 },  // Jan 3-4
    { startDay: 5, duration: 1 },  // Jan 5
    { startDay: 27, duration: 5 }, // Jan 27-31
    { startDay: 15, duration: 1 }, // Jan 15
    { startDay: 21, duration: 1 }, // Jan 21
    { startDay: 23, duration: 1 }  // Jan 23
  ];

  const start = parseDate(startDate);
  const end = parseDate(endDate);

  // Generate night shifts based on the pattern
  nightShiftPattern.forEach(({ startDay, duration }) => {
    const shiftStart = new Date(start.getFullYear(), start.getMonth(), startDay);
    const shiftEnd = new Date(shiftStart);
    shiftEnd.setDate(shiftStart.getDate() + duration);

    if (shiftStart >= start && shiftStart <= end) {
      assignments.push({
        title: 'Night Shift',
        start: shiftStart.toISOString(),
        end: shiftEnd.toISOString(),
        backgroundColor: '#27bcfd',
        textColor: '#fff',
        borderColor: 'transparent',
        allDay: true
      });
    }
  });

  // Add Day shifts (7:00 AM)
  const dayShiftDates = [12, 15, 18, 21];
  dayShiftDates.forEach(day => {
    const shiftDate = new Date(start.getFullYear(), start.getMonth(), day);
    if (shiftDate >= start && shiftDate <= end) {
      assignments.push({
        title: '7:00 AM Day Shift',
        start: shiftDate.toISOString(),
        backgroundColor: '#00d27a',
        textColor: '#fff',
        borderColor: 'transparent',
        allDay: true
      });
    }
  });

  return assignments;
};

// Calculate coverage from the first 10 days of the schedule
const calculateCoverageFromDays = (days) => {
  const firstTenDays = days.slice(0, 10);
  return {
    daily: {
      D: firstTenDays.map(day => day.shifts.filter(shift => shift.label === 'D').length),
      E: firstTenDays.map(day => day.shifts.filter(shift => shift.label === 'E').length),
      N: firstTenDays.map(day => day.shifts.filter(shift => shift.label === 'N').length)
    }
  };
};

const CoverageTable = ({ rotation }) => {
  // Generate dates for the table header
  const generateDates = () => {
    const start = parseDate(rotation.startDate);
    const dates = [];
    for (let i = 0; i < 10; i++) {  // Changed from 14 to 10
      const date = new Date(start);
      date.setDate(start.getDate() + i);
      dates.push({
        day: i + 1,
        weekday: date.toLocaleDateString('en-US', { weekday: 'short' }).charAt(0),
        date: date,
        isWeekend: date.getDay() === 0 || date.getDay() === 6
      });
    }
    return dates;
  };

  const dates = generateDates();
  const coverage = calculateCoverageFromDays(rotation.days);

  const shiftStyles = {
    D: {
      label: 'D',
      bgColor: '#00d27a', // green
      color: 'white',
      width: '40px'
    },
    E: {
      label: 'E',
      bgColor: '#f5803e', // orange
      color: 'white',
      width: '40px'
    },
    N: {
      label: 'N',
      bgColor: '#27bcfd', // blue
      color: 'white',
      width: '40px'
    }
  };

  const weekendBgColor = '#e3e6ed';

  return (
    <Card className="mb-3">
      <Card.Header className="bg-body-tertiary border-bottom">
        <h5 className="mb-0">Coverage</h5>
      </Card.Header>
      <Card.Body className="p-0">
        <Table responsive bordered hover className="mb-0 fs--1 border-200">
          <thead>
            <tr>
              <th className="bg-200 text-900 text-center align-middle" style={{ width: '40px' }}></th>
              {dates.map((date, index) => (
                <th 
                  key={index} 
                  style={{ 
                    minWidth: '40px',
                    ...(date.isWeekend && { backgroundColor: weekendBgColor })
                  }}
                  className="bg-200 text-900 text-center align-middle"
                >
                  <div className="text-dark fw-semi-bold">{date.day}</div>
                  <div className="text-600">{date.weekday}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {Object.entries(shiftStyles).map(([shift, style]) => (
              <tr key={shift}>
                <td 
                  className="text-center align-middle fw-semi-bold" 
                  style={{ 
                    backgroundColor: style.bgColor,
                    color: style.color,
                    width: style.width
                  }}
                >
                  {style.label}
                </td>
                {coverage.daily[shift].map((count, index) => (
                  <td 
                    key={index} 
                    className="text-center align-middle"
                    style={{
                      ...(dates[index].isWeekend && { backgroundColor: weekendBgColor })
                    }}
                  >
                    {count}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </Table>
      </Card.Body>
    </Card>
  );
};

const FormTitle = ({ title }) => {
  return (
    <Flex className="mb-3">
      <span className="fa-stack me-2 ms-n1">
        <FontAwesomeIcon icon="circle" className="fa-stack-2x text-300" />
        <FontAwesomeIcon
          icon="eye"
          className="fa-inverse fa-stack-1x text-primary"
        />
      </span>
      <div className="flex-1">
        <h5 className="mb-0 text-primary position-relative">
          <span className="bg-200 dark__bg-1100 pe-3">{title}</span>
          <span className="border position-absolute top-50 translate-middle-y w-100 start-0 z-n1"></span>
        </h5>
      </div>
    </Flex>
  );
};

const RotationDetails = ({ rotation }) => {
  return (
    <Card className="mb-3">
      <Card.Body>
        <Row>
          <Col md={6}>
            <h6 className="text-800">Planning Details</h6>
            <Row className="g-3 mb-3">
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Planning Horizon: </span>
                  <span className="text-800">{rotation.planningHorizon} Weeks</span>
                </div>
              </Col>
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Start Date: </span>
                  <span className="text-800">{rotation.startDate}</span>
                </div>
              </Col>
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">End Date: </span>
                  <span className="text-800">{rotation.endDate}</span>
                </div>
              </Col>
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Admin: </span>
                  <span className="text-800">{rotation.admin}</span>
                </div>
              </Col>
            </Row>

            <h6 className="text-800">Creation Details</h6>
            <Row className="g-3 mb-3">
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Date Created: </span>
                  <span className="text-800">{rotation.dateCreated}</span>
                </div>
              </Col>
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Approved Date: </span>
                  <span className="text-800">{rotation.approvedDate}</span>
                </div>
              </Col>
            </Row>
          </Col>

          <Col md={6}>
            <h6 className="text-800">Staff Details</h6>
            <Row className="g-3 mb-3">
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Full-Time Positions: </span>
                  <span className="text-800">{rotation.fullTimePositions}</span>
                </div>
              </Col>
              <Col sm={6}>
                <div className="fs--1">
                  <span className="text-600">Part-Time Positions: </span>
                  <span className="text-800">{rotation.partTimePositions}</span>
                </div>
              </Col>
            </Row>

            <h6 className="text-800">Shift Types</h6>
            <div className="fs--1 mb-3">
              {rotation.shiftTypes?.map((shift, index) => (
                <span key={index} className="badge badge-soft-primary me-2 mb-1">
                  {shift}
                </span>
              ))}
            </div>
          </Col>
        </Row>
      </Card.Body>
    </Card>
  );
};

const RequestsCalendar = ({ requests, rotation }) => {
  const {
    config: { isRTL }
  } = useAppContext();
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState(null);
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);

  const viewName = [
    'Month View',
    'Week View',
    'Day View',
    'List View'
  ];

  const handleFilter = filter => {
    if (!calendarApi) return;
    
    setCurrentFilter(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  const handleEventClick = info => {
    setSelectedEvent(info.event);
    setShowEventModal(true);
  };

  useEffect(() => {
    const api = calendarRef.current?.getApi();
    if (api) {
      setCalendarApi(api);
      setTitle(api.getCurrentData().viewTitle);
    }
  }, []);

  // Generate events for the calendar
  const events = useMemo(() => generateCalendarEvents(rotation), [rotation]);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    if (calendarApi) {
                      calendarApi.prev();
                      setTitle(calendarApi.getCurrentData().viewTitle);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    if (calendarApi) {
                      calendarApi.next();
                      setTitle(calendarApi.getCurrentData().viewTitle);
                    }
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-9 fs-sm-8 fs-lg-7">
                {title || ''}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  if (calendarApi) {
                    calendarApi.today();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }
                }}
              >
                Today
              </Button>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0 app-calendar">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={4}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            events={events}
            eventClick={handleEventClick}
            initialDate={parseDate(rotation.startDate)}
            slotDuration="01:00:00"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            nowIndicator={true}
            allDaySlot={true}
            eventDisplay="block"
            displayEventEnd={true}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showEventModal}
        onHide={() => setShowEventModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Shift Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <Flex className="mb-3">
              <div className="flex-1">
                <h6 className="mb-0">{selectedEvent.title}</h6>
                <div className="fs-9">
                  <span className="text-600">
                    <FontAwesomeIcon icon="calendar" className="me-2" />
                    {new Date(selectedEvent.start).toLocaleDateString()} 
                    {selectedEvent.end && ` - ${new Date(selectedEvent.end).toLocaleDateString()}`}
                  </span>
                </div>
              </div>
            </Flex>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEventModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

const ViewRotationPage = () => {
  const { rotationId } = useParams();
  const [rotation, setRotation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    // Simulate API call with mock data
    const fetchRotation = async () => {
      try {
        // Simulate network delay
        await new Promise(resolve => setTimeout(resolve, 500));
        
        const data = mockRotations[rotationId];
        
        if (!data) {
          throw new Error('Rotation not found');
        }
        
        setRotation(data);
        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchRotation();
  }, [rotationId]);

  const renderContent = () => {
    if (loading) {
      return (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="spinner-border text-primary" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      );
    }

    if (error) {
      return (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center text-danger">
            <div className="fs-1 mb-2">
              <span className="far fa-times-circle"></span>
            </div>
            <p>{error}</p>
          </div>
        </div>
      );
    }

    if (!rotation) {
      return (
        <div className="d-flex align-items-center justify-content-center h-100">
          <div className="text-center">
            <div className="fs-1 mb-2">
              <span className="far fa-question-circle"></span>
            </div>
            <p>Rotation not found</p>
          </div>
        </div>
      );
    }

    return (
      <>
        <RotationDetails rotation={rotation} />
        <ViewRotation rotation={rotation} />
        <CoverageTable rotation={rotation} />
        <RequestsCalendar requests={rotation.requests} rotation={rotation} />
      </>
    );
  };

  return (
    <Row className="g-3">
      <Col lg={12} xl={12} xxl={12}>
        <FormTitle title="View Rotation" />
        <Card className="theme-wizard">
          <Card.Header className="d-flex align-items-center justify-content-between">
            <h5 className="mb-0">{rotation?.name || 'Rotation Details'}</h5>
            <FalconLink
              to="/admin/rotations-list"
              icon="chevron-left"
              title="Back to Rotations"
              className="fs--1"
            />
          </Card.Header>
          <Card.Body className="fw-normal" style={{ height: 'calc(100vh - 255px)', overflow: 'auto' }}>
            {renderContent()}
          </Card.Body>
        </Card>
      </Col>
    </Row>
  );
};

export default ViewRotationPage; 