import React, { useEffect, useRef, useState } from 'react';
import {
  Button,
  Card,
  Col,
  OverlayTrigger,
  Row,
  Tooltip,
  Modal
} from 'react-bootstrap';
import { useAppContext } from 'providers/AppProvider';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import interactionPlugin from '@fullcalendar/interaction';
import DropdownFilter from 'components/common/DropdownFilter';
import Flex from 'components/common/Flex';

// Mock data for demonstration - replace with actual API call
const generateMockUserShifts = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();
  const firstDay = new Date(year, month, 1);
  const lastDay = new Date(year, month + 1, 0);
  const shifts = [];
  let id = 1;

  // Get the first Sunday of the month or the first day if it's a Sunday
  let startDate = new Date(firstDay);
  startDate.setDate(firstDay.getDate() - firstDay.getDay());

  // Get the last Saturday of the month or the last day if it's a Saturday
  let endDate = new Date(lastDay);
  if (endDate.getDay() !== 6) {
    endDate.setDate(lastDay.getDate() + (6 - lastDay.getDay()));
  }

  // Generate shifts for each day (randomly for demo)
  let currentDay = new Date(startDate);
  while (currentDay <= endDate) {
    // Randomly assign shifts (30% chance of having a shift)
    if (Math.random() < 0.3) {
      const isDay = Math.random() < 0.5;
      const shiftStart = new Date(currentDay);
      const shiftEnd = new Date(currentDay);
      
      if (isDay) {
        // Day shift: 07:00 to 15:00
        shiftStart.setHours(7, 0, 0);
        shiftEnd.setHours(15, 0, 0);
      } else {
        // Night shift: 23:00 to 07:00 next day
        shiftStart.setHours(23, 0, 0);
        shiftEnd.setDate(shiftEnd.getDate() + 1);
        shiftEnd.setHours(7, 0, 0);
      }

      const shift = {
        id: id++,
        title: isDay ? 'Day Shift' : 'Night Shift',
        start: shiftStart.toISOString(),
        end: shiftEnd.toISOString(),
        backgroundColor: isDay ? '#00d27a' : '#27bcfd',
        borderColor: 'transparent',
        role: Math.random() < 0.5 ? 'Primary' : 'Secondary',
        extendedProps: {
          date: currentDay.toLocaleDateString(),
          shiftType: isDay ? 'Day' : 'Night'
        }
      };
      shifts.push(shift);
    }
    currentDay.setDate(currentDay.getDate() + 1);
  }

  return shifts;
};

const UserCalendar = () => {
  const {
    config: { isRTL }
  } = useAppContext();
  const calendarRef = useRef();
  const [title, setTitle] = useState('');
  const [calendarApi, setCalendarApi] = useState({});
  const [currentFilter, setCurrentFilter] = useState('Month View');
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [showEventModal, setShowEventModal] = useState(false);
  const [shifts] = useState(generateMockUserShifts());

  const viewName = [
    'Month View',
    'Week View',
    'Day View',
    'List View'
  ];

  const handleFilter = filter => {
    setCurrentFilter(filter);
    switch (filter) {
      case 'Month View':
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Week View':
        calendarApi.changeView('timeGridWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'Day View':
        calendarApi.changeView('timeGridDay');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      case 'List View':
        calendarApi.changeView('listWeek');
        setTitle(calendarApi.getCurrentData().viewTitle);
        break;
      default:
        calendarApi.changeView('dayGridMonth');
        setTitle(calendarApi.getCurrentData().viewTitle);
    }
  };

  const handleEventClick = info => {
    setSelectedEvent(info.event);
    setShowEventModal(true);
  };

  useEffect(() => {
    setCalendarApi(calendarRef.current.getApi());
  }, []);

  return (
    <>
      <Card>
        <Card.Header>
          <Row className="align-items-center gx-0">
            <Col xs="auto" className="d-flex justify-content-end order-md-1">
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="nextTooltip">
                    Previous
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-1 ms-md-2"
                  onClick={() => {
                    calendarApi.prev();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-left" />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger
                placement="bottom"
                overlay={
                  <Tooltip style={{ position: 'fixed' }} id="previousTooltip">
                    Next
                  </Tooltip>
                }
              >
                <Button
                  variant="link"
                  className="icon-item icon-item-sm icon-item-hover shadow-none p-0 me-lg-2"
                  onClick={() => {
                    calendarApi.next();
                    setTitle(calendarApi.getCurrentData().viewTitle);
                  }}
                >
                  <FontAwesomeIcon icon="arrow-right" />
                </Button>
              </OverlayTrigger>
            </Col>
            <Col xs="auto" className="d-flex justify-content-end order-md-2">
              <h4 className="mb-0 fs-9 fs-sm-8 fs-lg-7">
                {title || `${calendarApi.currentDataManager?.data?.viewTitle}`}
              </h4>
            </Col>
            <Col xs md="auto" className="d-flex justify-content-end order-md-3">
              <Button
                size="sm"
                variant="falcon-primary"
                onClick={() => {
                  calendarApi.today();
                  setTitle(calendarApi.getCurrentData().viewTitle);
                }}
              >
                Today
              </Button>
            </Col>
            <Col className="d-flex justify-content-end order-md-2">
              <DropdownFilter
                className="me-2"
                filters={viewName}
                currentFilter={currentFilter}
                handleFilter={handleFilter}
                icon="sort"
                right
              />
            </Col>
          </Row>
        </Card.Header>
        <Card.Body className="p-0 app-calendar">
          <FullCalendar
            ref={calendarRef}
            headerToolbar={false}
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin
            ]}
            initialView="dayGridMonth"
            themeSystem="bootstrap"
            dayMaxEvents={4}
            direction={isRTL ? 'rtl' : 'ltr'}
            height={800}
            stickyHeaderDates={false}
            events={shifts}
            eventClick={handleEventClick}
            initialDate={new Date()}
            slotDuration="01:00:00"
            slotLabelFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            eventTimeFormat={{
              hour: 'numeric',
              minute: '2-digit',
              meridiem: true
            }}
            nowIndicator={true}
            allDaySlot={false}
          />
        </Card.Body>
      </Card>

      <Modal
        show={showEventModal}
        onHide={() => setShowEventModal(false)}
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>
            <h5 className="mb-0">Shift Details</h5>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedEvent && (
            <Flex className="mb-3">
              <div className="flex-1">
                <h6 className="mb-0">{selectedEvent.title}</h6>
                <div className="fs-9">
                  <span className={`badge rounded-pill ${selectedEvent.extendedProps.role === 'Primary' ? 'badge-subtle-success' : 'badge-subtle-info'} me-2`}>
                    {selectedEvent.extendedProps.role}
                  </span>
                  <span className="text-600">
                    <FontAwesomeIcon icon="calendar" className="me-2" />
                    {selectedEvent.extendedProps.date}
                  </span>
                </div>
              </div>
            </Flex>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowEventModal(false)}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default UserCalendar; 