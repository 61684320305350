import React, { useState } from 'react';
import { Card, Nav, Button, Modal } from 'react-bootstrap';
import classNames from 'classnames';
import { navItems, defaultShifts } from './wizard-steps/WizardCommon';
import UnionStep from './wizard-steps/UnionStep';
import FormatStep from './wizard-steps/FormatStep';
import ShiftsStep from './wizard-steps/ShiftsStep';
import StaffStep from './wizard-steps/StaffStep';
import HolidaysStep from './wizard-steps/HolidaysStep';
import RequestsStep from './wizard-steps/RequestsStep';
import CoverageStep from './wizard-steps/CoverageStep';

const ReviewStep = ({ 
  selectedUnion, 
  selectedRotation, 
  weekStartsOn, 
  numberOfDays, 
  shifts, 
  staffing, 
  selectedRules,
  holidays,
  requests,
  numberOfWeeks,
  coverageMatrix,
  setActiveTab 
}) => {
  const renderSection = (title, content, targetTab) => (
    <div className="review-section mb-4">
      <div className="d-flex justify-content-between align-items-center mb-2">
        <h5 className="mb-0">{title}</h5>
        <Button 
          variant="falcon-default" 
          size="sm"
          onClick={() => setActiveTab(targetTab)}
        >
          <span className="fas fa-edit"></span> Edit
        </Button>
      </div>
      <Card className="bg-body-tertiary">
        <Card.Body>{content}</Card.Body>
      </Card>
    </div>
  );

  return (
    <div className="review-step">
      {renderSection(
        'Collective Agreement',
        <div>
          <p className="mb-1"><strong>Selected Union:</strong> {selectedUnion}</p>
          <p className="mb-0"><strong>Selected Rules:</strong> {selectedRules.join(', ') || 'None'}</p>
        </div>,
        'Collective Agreement'
      )}

      {renderSection(
        'Format',
        <p className="mb-0"><strong>Rotation Type:</strong> {selectedRotation}</p>,
        'Format'
      )}

      {renderSection(
        'Shifts',
        <div>
          {shifts.map((shift, index) => (
            <p key={index} className="mb-1">
              <strong>{shift.name}:</strong> {shift.startTime} - {shift.endTime}
            </p>
          ))}
        </div>,
        'Shifts'
      )}

      {renderSection(
        'Staff',
        <div>
          <p className="mb-1"><strong>Full Time:</strong> {staffing.fullTime.count} staff ({staffing.fullTime.minHours}-{staffing.fullTime.maxHours} hours)</p>
          <p className="mb-1"><strong>Part Time:</strong> {staffing.partTime.count} staff ({staffing.partTime.minHours}-{staffing.partTime.maxHours} hours)</p>
          <p className="mb-0"><strong>Casual:</strong> {staffing.casual.count} staff ({staffing.casual.minHours}-{staffing.casual.maxHours} hours)</p>
        </div>,
        'Staff'
      )}

      {renderSection(
        'Holidays',
        <p className="mb-0"><strong>Number of Holidays:</strong> {holidays.length}</p>,
        'Holidays'
      )}

      {renderSection(
        'Requests',
        <p className="mb-0"><strong>Number of Requests:</strong> {requests.length}</p>,
        'Requests'
      )}

      {renderSection(
        'Coverage',
        <div>
          <p className="mb-1"><strong>Number of Weeks:</strong> {numberOfWeeks}</p>
          <p className="mb-0"><strong>Coverage Matrix:</strong> {Object.keys(coverageMatrix).length} entries</p>
        </div>,
        'Coverage'
      )}
    </div>
  );
};

const CustomWizard = () => {
  const [activeTab, setActiveTab] = useState('Collective Agreement');
  const [selectedUnion, setSelectedUnion] = useState('bc_ehs');
  const [selectedRotation, setSelectedRotation] = useState('block');
  const [weekStartsOn, setWeekStartsOn] = useState('Friday');
  const [numberOfDays, setNumberOfDays] = useState(49);
  const [shifts, setShifts] = useState(defaultShifts);
  const [staffing, setStaffing] = useState({
    fullTime: { count: 5, minHours: 38, maxHours: 38 },
    partTime: { count: 3, minHours: 25, maxHours: 25 },
    casual: { count: 4, minHours: 15, maxHours: 15 }
  });
  const [selectedRules, setSelectedRules] = useState([]);
  const [holidays, setHolidays] = useState([]);
  const [requests, setRequests] = useState([]);
  const [numberOfWeeks, setNumberOfWeeks] = useState(2);
  const [coverageMatrix, setCoverageMatrix] = useState({});
  const [modalShow, setModalShow] = useState(false);

  const handleNext = () => {
    const currentIndex = navItems.findIndex(item => item.label === activeTab);
    if (currentIndex < navItems.length - 1) {
      setActiveTab(navItems[currentIndex + 1].label);
    }
  };

  const handleBack = () => {
    const currentIndex = navItems.findIndex(item => item.label === activeTab);
    if (currentIndex > 0) {
      setActiveTab(navItems[currentIndex - 1].label);
    }
  };

  const isFirstStep = activeTab === navItems[0].label;
  const isLastStep = activeTab === navItems[navItems.length - 1].label;

  const handleSubmit = () => {
    setModalShow(true);
  };

  const renderStepContent = () => {
    switch (activeTab) {
      case 'Collective Agreement':
        return (
          <UnionStep
            selectedUnion={selectedUnion}
            setSelectedUnion={setSelectedUnion}
            selectedRules={selectedRules}
            setSelectedRules={setSelectedRules}
          />
        );
      case 'Format':
        return (
          <FormatStep
            selectedRotation={selectedRotation}
            setSelectedRotation={setSelectedRotation}
            weekStartsOn={weekStartsOn}
            setWeekStartsOn={setWeekStartsOn}
            numberOfDays={numberOfDays}
            setNumberOfDays={setNumberOfDays}
          />
        );
      case 'Shifts':
        return <ShiftsStep shifts={shifts} setShifts={setShifts} />;
      case 'Staff':
        return <StaffStep staffing={staffing} setStaffing={setStaffing} />;
      case 'Holidays':
        return <HolidaysStep holidays={holidays} setHolidays={setHolidays} />;
      case 'Requests':
        return <RequestsStep requests={requests} setRequests={setRequests} />;
      case 'Coverage':
        return (
          <CoverageStep
            numberOfWeeks={numberOfWeeks}
            setNumberOfWeeks={setNumberOfWeeks}
            coverageMatrix={coverageMatrix}
            setCoverageMatrix={setCoverageMatrix}
            shifts={shifts.filter(shift => shift.enabled && shift.id !== 'off')}
          />
        );
      case 'Review':
        return (
          <ReviewStep
            selectedUnion={selectedUnion}
            selectedRotation={selectedRotation}
            weekStartsOn={weekStartsOn}
            numberOfDays={numberOfDays}
            shifts={shifts}
            staffing={staffing}
            selectedRules={selectedRules}
            holidays={holidays}
            requests={requests}
            numberOfWeeks={numberOfWeeks}
            coverageMatrix={coverageMatrix}
            setActiveTab={setActiveTab}
          />
        );
      default:
        return <div>Step content not implemented yet</div>;
    }
  };

  return (
    <>
      <Card className="theme-wizard">
        <Card.Header className="bg-body-tertiary pb-2">
          <Nav className="nav-tabs">
            {navItems.map(item => (
              <Nav.Item key={item.label}>
                <Nav.Link
                  className={classNames({ active: item.label === activeTab })}
                  onClick={() => setActiveTab(item.label)}
                >
                  {item.label}
                </Nav.Link>
              </Nav.Item>
            ))}
          </Nav>
        </Card.Header>
        <Card.Body className="fw-normal px-md-6 py-4" style={{ height: 'calc(100vh - 320px)', overflow: 'auto' }}>
          {renderStepContent()}
        </Card.Body>
        <Card.Footer className="px-md-6 bg-body-tertiary d-flex">
          {!isFirstStep && (
            <Button variant="link" onClick={handleBack}>
              Back
            </Button>
          )}
          <Button 
            variant="primary" 
            onClick={isLastStep ? handleSubmit : handleNext} 
            className="ms-auto"
          >
            {isLastStep ? 'Submit' : 'Next'}
          </Button>
        </Card.Footer>
      </Card>

      <Modal
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">Rotation Created</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Your new rotation has been created. Once the candidate rotations are generated you will be notified by email and will be able to view and modify the rotation under the Available Rotations.
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={() => setModalShow(false)}>Close</Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default CustomWizard;
